<style type="text/css">
@import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
.custom-file-label::after {
  display: none;
}
.time_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropDown {
  max-height: 150px;
  width: 100%;
  overflow-y: scroll;
  background: white;
  position: absolute;
  z-index: 999999;
  border: 1px solid #dcdfe6;
}
.dropDown div {
  cursor: pointer;
  padding: 0 15px;
}
.dropDown div:hover {
  background: #dcdfe6;
}
.log-form-box {
  height: 40px;
}
/deep/ .el-form-item__label {
  margin: 0;
}
.log-label {
  width: 150px;
  padding: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}
.xldr {
  line-height: unset !important;
}
/deep/ .el-form-item__content {
  line-height: unset !important;
}
</style>
<script>
import $ from "jquery";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import PersonHeader from "@/views/pages/examinee/person-header.vue";
import recheck from "@/components/edu/recheck";
import {
  handleChange,
  changeOption,
  getDictionary,
  boundDefaultData,
  unitsChange,
  checkFile,
  getUserInfo,
} from "@/libs/common";

import {
  getUserEdu,
  updateUserEdu,
  deleteUserEdu,
  saveXLZM,
  getXXWEdu,
} from "@/api/userInfo.js";
import { getUploadLimit, uploadFiles } from "@/api/index";
/**
 * PersonData--imgage
 */
export default {
  components: {
    Layout,
    PageHeader,
    PersonHeader,
    recheck,
  },
  data() {
    return {
      title: "个人资料完善",
      items: [
        {
          text: "资料完善",
        },
        {
          text: "学历信息",
          active: true,
        },
      ],
      userInfo: {},
      page: "edu",
      show: "",
      astrict: {
        xlzm: {},
      },
      pickerOptions_start: "",
      pickerOptions_end: "",
      sjzd: {},
      edit: {},
      eduList: [],
      filterList: [],
      showmodal: false,
      dropShow: false,
      chooseIndex: null,
      EduactionForm: {
        rxny: "",
        jsxyny: "",
        xxfsmc: "",
        xxfsm: "",
        xxxsmc: "",
        xxxsm: "",
        gjdqm: "CHN",
        gjdqmc: "中国",
        byyxxhdw: "",
        sxzym: "",
        sxzymc: "",
        xlmc: "",
        xlm: "",
        xlzsh: "",
        hdxwm: "",
        hdxwmc: "",
        xwzsh: "",
      },
      rules: {
        rxny: [
          {
            required: true,
            message: "请选择入校时间",
            trigger: "change",
          },
        ],
        jsxyny: [
          {
            required: true,
            message: "请选择结束学业时间",
            trigger: "change",
          },
        ],
        gjdqm: [
          {
            required: true,
            message: "请选择国家地区",
            trigger: "change",
          },
        ],
        byyxxhdw: [
          {
            required: true,
            message: "请输入学校名称",
            trigger: "change",
          },
        ],
        xlm: [
          {
            required: true,
            message: "请输入获得学历",
            trigger: "change",
          },
        ],
        xlzsh: [
          {
            required: true,
            message: "输入学历证书号",
            trigger: "change",
          },
        ],
        xxfsm: [
          {
            required: true,
            message: "请选择学习方式",
            trigger: "change",
          },
        ],
        xxxsm: [
          {
            required: true,
            message: "请选择学习形式",
            trigger: "change",
          },
        ],
        sxzymc: [
          {
            required: true,
            message: "请填写学习专业",
            trigger: "change",
          },
        ],
      },
      xxwEduList: [],
      operateType: "add",
      xlDict: {
        byrq: "jsxyny",
        cc: "xlm",
        rxrq: "rxny",
        // xxxs: "普通全日制",
        yxmc: "byyxxhdw",
        // zsbh: "xlzsh",
        zymc: "sxzymc",
      },
      xlcc: ["专科", "本科", "硕士研究生", "博士研究生"],
      reEduForm_dialog: false,
      sfdrEdu: false,
    };
  },
  methods: {
    open(sid) {
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteUserEdu(sid).then((res) => {
            if (res.status) {
              this.getEdu();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch();
    },
    submitEdu() {
      this.$confirm(
        "上传学历后无法修改和删除，且最多添加5条记录, 请认真填写?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          updateUserEdu(this.EduactionForm).then((res) => {
            if (res.status) {
              this.showmodal = false;
              this.reEduForm_dialog = false;
              this.getEdu();
            }
          });
          return false;
        })
        .catch();
    },
    checkForm() {
      this.$refs["EduactionForm"].validate((valid) => {
        if (valid) {
          this.reEduForm_dialog = true;
          this.showmodal = false;
        }
      });
    },
    addEdu() {
      if (this.eduList.length >= 5) {
        this.$alert("学历记录最多添加5条", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            return;
          },
        });
      } else {
        this.ImportEdu();
        for (const key in this.EduactionForm) {
          this.EduactionForm[key] = "";
        }
        let item = this.sjzd.dict_gjdq.filter(x=>{return x.value == "CHN"})[0]
        this.EduactionForm.gjdqm="CHN",
        this.EduactionForm.gjdqm_=item,
        this.EduactionForm.gjdqmc = "中国",
        this.operateType = "add";
        this.sfdrEdu = false;
        this.showmodal = true;
      }
    },
    editEdu(index) {
      this.ImportEdu();
      this.operateType = "edit";
      this.EduactionForm = JSON.parse(JSON.stringify(this.eduList[index]));
      let formData = this.EduactionForm;
      this.checkXL(formData.xlm[0]);
      this.model();
      let data = {
        zyList: "sxzym",
      };
      boundDefaultData(this.sjzd, formData, data);
      this.$forceUpdate();
      this.showmodal = true;
    },
    deleteEdu: function (index) {
      let sid = this.eduList[index].sid;
      this.open(sid);
    },
    changeOption(event, form, kv) {
      changeOption(event, form, kv);
      this.$forceUpdate();
    },
    handleChange(event, form, name, code) {
      handleChange(event, form, name, code);
    },

    changeDate() {
      let _this = this;
      //因为date1和date2格式为 年-月-日， 所以这里先把date1和date2转换为时间戳再进行比较
      let date_start = new Date(
        _this.EduactionForm.rxny.replace(/-/g, "/")
      ).getTime();
      let date_end = new Date(
        _this.EduactionForm.jsxyny.replace(/-/g, "/")
      ).getTime();

      _this.pickerOptions_start = {
        disabledDate: (time) => {
          if (date_end != "") {
            return time.getTime() > Date.now() || time.getTime() > date_end;
          } else {
            return time.getTime() > Date.now();
          }
        },
      };
      _this.pickerOptions_end = {
        disabledDate: (time) => {
          return time.getTime() < date_start;
        },
        shortcuts: [
          {
            text: "至今",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
        ],
      };
    },

    model() {
      let sjzd = this.sjzd;
      let fromData = this.EduactionForm;
      let data = {
        dict_gjdq: "gjdqm",
        dict_xl: "xlm",
        dict_xw: "hdxwm",
        dict_xxfs: "xxfsm",
        dict_xxxs: "xxxsm",
      };
      boundDefaultData(sjzd, fromData, data);
      this.$forceUpdate();
    },
    getEdu() {
      getUserEdu().then((res) => {
        if (res.status) {
          this.eduList = res.data;
        }
      });
    },
    zyChange(val) {
      let code = this.EduactionForm.gjdqm;
      if (code == "CHN" || code == "HKG" || code == "MAC" || code == "TWN") {
        if (val.length >= 2) {
          this.dropShow = true;
          this.filterList = this.sjzd.zyList.filter((item) => {
            return item.name.includes(val);
          });
        } else {
          this.filterList = [];
        }
        let filterData = this.filterList.filter((item) => {
          return item.name == val;
        });
        if (filterData.length != 0) {
          this.EduactionForm.sxzym = filterData[0].value;
        } else {
          this.EduactionForm.sxzym = "";
        }
      }
    },
    chooseZY(data) {
      this.EduactionForm.sxzymc = data.name;
      this.EduactionForm.sxzym = data.value;
      this.dropShow = false;
    },
    xlChange($event) {
      this.changeOption($event, this.EduactionForm, ["xlmc", "xlm"]);
      let code = $event.value[0];
      this.checkXL(code);
    },
    checkXL(code) {
      if (code == "1") {
        this.sjzd.zyList = this.sjzd.dict_zydm_yjs;
      } else if (code == "2") {
        this.sjzd.zyList = this.sjzd.dict_zydm_bk;
      } else if (code == "3" || code == "4") {
        this.sjzd.zyList = this.sjzd.dict_zydm_zk;
      } else {
        this.sjzd.zyList = [];
      }
    },
    inputBlur() {
      setTimeout(() => {
        this.dropShow = false;
      }, 500);
    },
    getLimit() {
      getUploadLimit({ module: "UserEducation" }).then((res) => {
        if (res.status) {
          this.astrict.xlzm["ext"] =
            "." + res.data.extLimit.replaceAll(",", ",.");
          this.astrict.xlzm["size"] = res.data.sizeLimit;
          let size = unitsChange(res.data.sizeLimit);
          this.astrict.xlzm["text"] =
            "只支持" + res.data.extLimit + "，类型的文件，且不超过" + size;
        }
      });
    },
    uploadFile(file, key) {
      let _this = this;
      let fileRule = "";
      file = file.raw;
      let result = checkFile(file, this.astrict[key]);

      if (result.status) {
        if (key == "xlzm") {
          fileRule = "UserEducation";
        }
        uploadFiles(fileRule, file).then((res) => {
          if (res.status) {
            let resFile = res.data[0];

            saveXLZM({
              sid: _this.eduList[_this.chooseIndex].sid,
              xlzm: resFile.fileId,
            }).then((res) => {
              _this.$bvModal.hide("submitXLZM");
              _this.getEdu();
              _this.$message({ type: "success", message: "上传成功" });
            });
          } else {
            _this.$message({ type: "error", message: res.error });
          }
        });
      } else {
        _this.$message({ type: "error", message: result.message });
      }
      this.$refs[key].clearFiles();
      return false;
    },
    ImportEdu() {
      if (this.xxwEduList.length == 0) {
        getXXWEdu().then((res) => {
          if (res.status) {
            if (res.data) {
              res.data.forEach((item) => {
                item.rxrq =
                  item.rxrq.slice(0, 4) +
                  "-" +
                  item.rxrq.slice(4, 6) +
                  "-" +
                  item.rxrq.slice(6, 8);
                item.byrq =
                  item.byrq.slice(0, 4) +
                  "-" +
                  item.byrq.slice(4, 6) +
                  "-" +
                  item.byrq.slice(6, 8);
              });
              this.xxwEduList = res.data;
            }
          }
        });
      }
    },
    Import(index) {
      let data = this.xxwEduList[index];
      console.log(data);
      for (const key in this.xlDict) {
        if (key == "cc") {
          let xlm = "";
          let xlmc = "";
          switch (data[key]) {
            case "专科":
              xlm = "31";
              xlmc = "大学专科毕业";
              break;

            case "本科":
              xlm = "21";
              xlmc = "大学本科毕业";
              break;

            case "硕士研究生":
              xlm = "14";
              xlmc = "硕士研究生毕业";
              break;

            case "博士研究生":
              xlm = "11";
              xlmc = "博士研究生毕业";
              break;

            default:
              break;
          }
          let item = this.sjzd.dict_xl.filter(x=>{return x.value == xlm})[0]
          this.EduactionForm["xlm"] = xlm;
          this.EduactionForm["xlm_"] = item;
          this.EduactionForm["xlmc"] = xlmc;

        } else {
          this.EduactionForm[this.xlDict[key]] = data[key];
        }
      }
      this.$bvModal.hide("importXL");
      this.sfdrEdu = true;
    },
    recheck() {},
  },
  created() {
    let show = this.$route.query.show;
    if (show) {
      this.show = show;
    }
    this.getEdu();
    this.getLimit();
    getDictionary(
      "dict_gjdq",
      "dict_xl",
      "dict_xw",
      "dict_xxfs",
      "dict_xxxs",
      "dict_zydm_bk",
      "dict_zydm_yjs",
      "dict_zydm_zk"
    ).then((res) => {
      res["zyList"] = [];
      res["xlList"] = [];
      res["xwList"] = [];
      //学历
      for (let index = 1; index < 10; index++) {
        let list = res.dict_xl.filter((item) => {
          return item.value[0] == index;
        });
        let obj = {
          lable: "",
          options: [],
        };
        if (list.length != 0) {
          obj.lable = list.filter((v) => {
            return v.value[1] == 0;
          })[0];
          obj.options = list.filter((v) => {
            return v.value[1] != 0;
          });
          if (obj.options.length != 0) {
            res.xlList.push(obj);
          }
        }
      }

      //学位
      for (let index = 0; index < 5; index++) {
        let list = res.dict_xw.filter((item) => {
          return item.value[0] == index;
        });
        let obj = {
          lable: "",
          options: [],
        };
        if (list.length != 0) {
          obj.lable = list.filter((v) => {
            return v.value.length == 1;
          })[0];
          obj.options = list.filter((v) => {
            return v.value.length > 1;
          });
          if (obj.options.length != 0) {
            res.xwList.push(obj);
          }
        }
      }
      res.xwList.unshift({
        lable: { name: "无", value: 0 },
        options: [{ name: "无", value: 0 }],
      });
      this.sjzd = res;
    });
    let _this = this;
    getUserInfo().then((data) => {
      if (data) {
        _this.userInfo = data;
      }
    });
    this.changeDate();
  },
  mounted() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <PersonHeader :page="page" :show="show" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash">
              <span class="mr-4">学习经历添加及学历认证</span>
              <span class="mr-2"
                ><i class="iconfont icon-ze-user-o mr-2"></i>姓名</span
              >
              <span class="mr-2">{{ userInfo.xm }}</span>
              <span class="mr-2"
                ><i class="iconfont icon-idcard1e mr-2"></i>居民身份证/社会保障卡</span
              >
              <span class="mr-2">{{ userInfo.sfzjh }}</span>
            </h4>
            <div class="mb-4">
              <img
                src="@/assets/images/person/if-plus-circle.png"
                class="mr-2"
              /><button
                type="button"
                class="btn btn-info h30 mr-2"
                @click="addEdu"
              >
                添加学习经历
              </button>

              <!-- <span class="grey-time">从高中学习经历开始添加</span> -->
            </div>
            <div class="table-responsive">
              <table class="table mb-0 text-center" v-if="eduList">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%">序号</th>
                    <th style="width: 12%">起止时间</th>
                    <th style="width: 5%">国家地区</th>
                    <th>学校名称</th>
                    <th style="width: 10%">学历层次</th>
                    <th style="width: 10%">学历证书号</th>
                    <th style="width: 10%">所学专业</th>
                    <th style="width: 10%">获得学位</th>
                    <th style="width: 10%">学位证书号</th>
                    <th style="width: 10%">学历核查状态</th>
                    <!-- <th style="width: 8%">操作</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in eduList" :key="index">
                    <td scope="row">{{ index + 1 }}</td>
                    <td>{{ item.rxny }}至{{ item.jsxyny }}</td>
                    <td>{{ item.gjdqmc }}</td>
                    <td>{{ item.byyxxhdw }}</td>
                    <td>{{ item.xlmc }}</td>
                    <td>
                      <div class="line1">{{ item.xlzsh }}</div>
                    </td>
                    <td>{{ item.sxzymc || "/" }}</td>
                    <td>{{ item.hdxwmc || "/" }}</td>
                    <td>{{ item.xwzsh || "/" }}</td>
                    <td>
                      <div
                        class="text-success"
                        v-if="item.xlzm"
                        @click="chooseIndex = index"
                        v-b-modal.submitXLZM
                      >
                        <i class="iconfont icon-a-ze-checkedCopy2 mr-2"></i
                        >{{ "学历证明已上传" }}
                      </div>
                      <div
                        class="text-success"
                        v-if="
                          (item.xlshzt ? item.xlshzt : item.xlxtrzzt) == 1 &&
                          !item.xlzm
                        "
                      >
                        <i class="iconfont icon-a-ze-checkedCopy2 mr-2"></i
                        >{{
                          (item.xlshzt ? item.xlshbz : item.xwxtrzsm) ||
                          "在线核查已通过"
                        }}
                      </div>

                      <div
                        class="text-danger"
                        v-if="
                          (item.xlshzt ? item.xlshzt : item.xlxtrzzt) == 2 &&
                          !item.xlzm
                        "
                      >
                        <div>
                          <i class="iconfont icon-ze-clear mr-2"></i
                          >{{
                            (item.xlshzt ? item.xlshbz : item.xwxtrzsm) ||
                            "在线核查不通过"
                          }}
                        </div>
                        <div>
                          <a
                            href="javascript: void(0);"
                            v-b-modal.submitXLZM
                            v-if="!item.xlzm"
                            @click="chooseIndex = index"
                            >点此提交校验材料</a
                          >
                        </div>
                      </div>
                      <div
                        class="text-secondary"
                        v-if="
                          !(item.xlshzt ? item.xlshzt : item.xlxtrzzt) &&
                          !item.xlzm
                        "
                      >
                        <div>
                          <i class="iconfont icon-icon_minus-circled mr-2"></i
                          >未审核
                        </div>
                        <div>
                          <a
                            href="javascript: void(0);"
                            v-b-modal.submitXLZM
                            v-if="!item.xlzm"
                            @click="chooseIndex = index"
                            >点此提交校验材料</a
                          >
                        </div>
                      </div>
                    </td>
                    <!-- <td class="handle">
                      <div
                        v-if="(item.xlshzt ? item.xlshzt : item.xlxtrzzt) != 1"
                      >
                        <i
                          class="iconfont icon-icon_edit mr-2"
                          @click="editEdu(index)"
                        ></i
                        ><i
                          class="iconfont icon-riLine-delete-bin-line mr-2"
                          @click="deleteEdu(index)"
                        ></i>
                      </div>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>

            <a href="/personalData"
              ><button type="button" class="btn btn-info h35 sub-btn">
                已完善，继续下一步
              </button></a
            >
            <div style="margin: 10px 0; font-weight: 600; line-height: 30px">
              <p>注意事项：</p>
              1.系统可对2002年至今大专以上（含大专）的学历信息进行在线核验。<br />
              2.学历信息最少填写1条信息，最多可填写5条信息。<br />
              3.请如实准确填写学历信息，一旦保存后将无法删除。<br />
              4.学历学位信息在线核验“<b class="text-danger">在线核查未通过</b
            >”的不会影响报名信息填报，但须在报名前上传相关证书原件电子照片【暂未取得证书的应届毕业生请前往学信网（<a href="https://www.chsi.com.cn/" target="_blank">https://www.chsi.com.cn/</a>）下载《教育部学籍在线验证报告》并上传】。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加学习经历弹窗 -->
    <b-modal
      id="addEducation"
      v-model="showmodal"
      centered
      :title="operateType == 'add' ? '添加学习经历' : '修改学习经历'"
      size="lg"
      title-class="font-18"
      hide-footer
    >
      <el-form
        :model="EduactionForm"
        :rules="rules"
        ref="EduactionForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- <el-form-item
          label="学信网导入"
          class="w-50"
          v-if="operateType == 'add'"
        >
          <button
            type="button"
            class="btn btn-info h30 mr-2"
            v-b-modal.importXL
            @click="ImportEdu"
          >
            查询导入学习经历
          </button>
        </el-form-item> -->
        <el-form-item
          label="学信网导入"
          class="w-100"
          v-if="operateType == 'add'"
          style="margin-bottom: unset"
        >
          <div class="mb-2 xldr" style="margin-top: -10px">
            <div style="margin-top: 10px; margin-bottom: 10px">
              <div>说明：1、此学历信息来源学信网。</div>
            </div>
            <div class="border">
              <table
                class="table table-has-bg light-table table-hover table-bordered"
              >
                <thead class="thead-light">
                  <tr>
                    <th style="width: 10%">序号</th>
                    <th>学校名称</th>
                    <th style="width: 15%">学习层次</th>
                    <th style="width: 30%">学习起止时间</th>
                    <th style="width: 15%">操作</th>
                  </tr>
                </thead>
                <tbody v-if="xxwEduList.length != 0">
                  <tr v-for="(item, index) in xxwEduList" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                      {{ item.yxmc }}
                    </td>
                    <td>
                      {{ item.cc }}
                    </td>
                    <td>
                      {{ item.rxrq }} <span class="m-1">至</span>{{ item.byrq }}
                    </td>

                    <td>
                      <button
                        type="button"
                        class="btn btn-info h30 mr-2"
                        @click="Import(index)"
                      >
                        导入
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center" v-if="xxwEduList.length == 0">
                无可导入信息
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item style="margin-bottom: unset"
          >说明：“学历证书”号应届毕业生填写“暂无”。</el-form-item
        >
        <div class="time_group">
          <el-form-item label="入校时间" prop="rxny" class="w-50">
            <el-date-picker
              v-model="EduactionForm.rxny"
              :readonly="sfdrEdu"
              type="date"
              class="w-100"
              placeholder="入校时间"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions_start"
              @input="changeDate"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="毕业时间" prop="jsxyny" class="w-50">
            <el-date-picker
              v-model="EduactionForm.jsxyny"
              :readonly="sfdrEdu"
              type="date"
              class="w-100"
              placeholder="离校时间"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions_end"
              @input="changeDate"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item label="国家地区" prop="gjdqm">
          <el-select
            v-model="EduactionForm.gjdqm_"
            placeholder="请选择国家地区"
            class="w-100"
            value-key="name"
            @change="changeOption($event, EduactionForm, ['gjdqmc', 'gjdqm'])"
          >
            <el-option
              v-for="(v, i) in sjzd.dict_gjdq"
              :label="v.name"
              :value="v"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学校名称" prop="byyxxhdw">
          <el-input
            type="text"
            v-model="EduactionForm.byyxxhdw"
            :readonly="sfdrEdu"
            placeholder="请输入学校名称"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="学历层次" prop="xlm">
          <el-select
            v-model="EduactionForm.xlm_"
            class="w-100"
            placeholder="请选择学历"
            value-key="name"
            @change="xlChange($event)"
          >
            <el-option-group
              v-for="(v, i) in sjzd.xlList"
              :key="i"
              :label="v.lable.name"
            >
              <el-option
                v-for="item in v.options"
                :key="item.value"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="学历证书号" prop="xlzsh">
          <el-input
            type="text"
            v-model="EduactionForm.xlzsh"
            placeholder="输入学历证书号（应届毕业生填“暂无”）"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="学习方式" prop="xxfsm">
          <el-select
            v-model="EduactionForm.xxfsm_"
            class="w-100"
            placeholder="请选择学习方式"
            value-key="name"
            @change="changeOption($event, EduactionForm, ['xxfsmc', 'xxfsm'])"
          >
            <el-option
              v-for="(v, i) in sjzd.dict_xxfs"
              :label="v.name"
              :value="v"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="培养方式" prop="xxxsm">
          <el-select
            v-model="EduactionForm.xxxsm_"
            class="w-100"
            placeholder="请选择学习形式"
            value-key="name"
            @change="changeOption($event, EduactionForm, ['xxxsmc', 'xxxsm'])"
          >
            <el-option
              v-for="(v, i) in sjzd.dict_xxxs"
              :label="v.name"
              :value="v"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所学专业" prop="sxzymc">
          <el-input
            type="text"
            v-model="EduactionForm.sxzymc"
            :readonly="sfdrEdu"
            placeholder="请输入所学专业"
            autocomplete="off"
            @input="zyChange"
            @blur="inputBlur"
          ></el-input>
          <div v-if="filterList.length != 0 && dropShow" class="dropDown">
            <div
              v-for="(item, index) in filterList"
              :key="index"
              @click="chooseZY(item)"
            >
              {{ item.name }} - {{ item.value }}
            </div>
          </div>
        </el-form-item>
        <el-form-item label="获得学位" prop="hdxwm">
          <el-select
            v-model="EduactionForm.hdxwm_"
            class="w-100"
            placeholder="请选择学位"
            value-key="name"
            @change="changeOption($event, EduactionForm, ['hdxwmc', 'hdxwm'])"
          >
            <el-option-group
              v-for="(v, i) in sjzd.xwList"
              :key="i"
              :label="v.lable.name"
            >
              <el-option
                v-for="item in v.options"
                :key="item.value"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="学位证书号" prop="xwzsh">
          <el-input
            type="text"
            v-model="EduactionForm.xwzsh"
            placeholder="请输入学位证书号"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-info h30 w-md mr-3"
            @click="checkForm"
          >
            {{ operateType == "add" ? "确定添加" : "确定修改" }}
          </button>
          <button
            type="button"
            class="btn btn-secondary h30 w-md"
            @click="showmodal = false"
          >
            取消
          </button>
        </div>
      </el-form>
    </b-modal>
    <!-- 添加学习经历弹窗结束 -->

    <!-- 提交学历证明材料弹窗 -->
    <b-modal
      id="submitXLZM"
      centered
      title="提交学历核验材料"
      size="lg"
      title-class="font-18"
      hide-footer
      v-if="chooseIndex !== null"
    >
      <div class="mb-2" style="margin-top: -10px">
        <div>
          <div>说明：</div>
          <div>
            1、专科、本科、研究生学历系统会自动校验，如校验无法通过，请务必上传校验材料，通过审核后才能提交考试报名。
          </div>
          <div>
            2、提交材料为毕业证书扫描件（彩色），如在境外取得学历证书或其他情况，除上传毕业证（境外高校提供学位证）扫描件外，还需提交教育主管部门出具的学历认证报告。
          </div>
        </div>
        <div class="border">
          <div class="log-form-box">
            <div class="log-label mr-3">起止时间</div>
            <div class="log-form-r">
              {{ eduList[chooseIndex].rxny }}至{{ eduList[chooseIndex].jsxyny }}
            </div>
          </div>
          <div class="log-form-box">
            <div class="log-label mr-3">国家地区</div>
            <div class="log-form-r">{{ eduList[chooseIndex].gjdqmc }}</div>
          </div>
          <div class="log-form-box">
            <div class="log-label mr-3">毕业学校及专业</div>
            <div class="log-form-r">
              {{ eduList[chooseIndex].byyxxhdw }}
              {{ eduList[chooseIndex].sxzymc }}
            </div>
          </div>
          <div class="log-form-box">
            <div class="log-label mr-3">学历层次</div>
            <div class="log-form-r">{{ eduList[chooseIndex].xlmc }}</div>
          </div>
          <div class="log-form-box">
            <div class="log-label mr-3">学历证书号</div>
            <div class="log-form-r">{{ eduList[chooseIndex].xlzsh }}</div>
          </div>
          <div class="log-form-box" style="height: 100px">
            <div class="log-label mr-3">上传学历证明材料</div>
            <div class="" style="flex: 1">
              <el-upload
                v-if="!eduList[chooseIndex].xlzm"
                class="upload-demo d-flex align-items-center"
                action=""
                ref="xlzm"
                :auto-upload="false"
                :on-change="
                  (file) => {
                    return uploadFile(file, 'xlzm');
                  }
                "
                :limit="1"
                :show-file-list="false"
                :accept="astrict.xlzm.ext"
              >
                <el-button size="small"
                  ><i
                    class="iconfont icon-tongyong_2_icontubiao_fill_upload mr-2"
                  ></i
                  >上传文件</el-button
                >
                <div slot="tip" style="margin-left: 10px">
                  <span>若学历审核未通过，必须上传学历证明文件</span>
                </div>
              </el-upload>
              <div slot="tip" style="margin-left: 10px">
                <a
                  v-if="eduList[chooseIndex].xlzm"
                  @click="
                    $yzImgDialog().show(getFileUrl(eduList[chooseIndex].xlzm))
                  "
                  href="javascript:;"
                  >查看文件</a
                >
              </div>
              <div slot="tip" class="mt-2">
                只能上传pdf或jpg文件，毕业证书扫描件（彩色），如在境外取得学历证书或其他情况，除上传毕业证（境外高校提供学位证）扫描件外，还需提交教育主管部门出具的学历认证报告
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- 提交学历证明材料弹窗结束 -->

    <!-- 导入学历弹窗 -->
    <b-modal
      id="importXL"
      centered
      title="导入学历信息"
      size="lg"
      title-class="font-18"
      hide-footer
    >
      <div class="mb-2" style="margin-top: -10px">
        <div>
          <div>说明：</div>
          <div>1、此学历信息来源学信网。</div>
        </div>
        <div class="border">
          <table
            class="table table-has-bg light-table table-hover table-bordered"
          >
            <thead class="thead-light">
              <tr>
                <th style="width: 5%">序号</th>
                <th>学校名称</th>
                <th style="width: 15%">学习层次</th>
                <th style="width: 30%">学习起止时间</th>
                <th style="width: 10%">操作</th>
              </tr>
            </thead>
            <tbody v-if="xxwEduList.length != 0">
              <tr v-for="(item, index) in xxwEduList" :key="index">
                <th scope="row">{{ index + 1 }}</th>
                <td>
                  {{ item.yxmc }}
                </td>
                <td>
                  {{ item.cc }}
                </td>
                <td>
                  {{ item.rxrq }} <span class="m-1">至</span>{{ item.byrq }}
                </td>

                <td>
                  <button
                    type="button"
                    class="btn btn-info h30 mr-2"
                    @click="Import(index)"
                  >
                    导入
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="text-center" v-if="xxwEduList.length == 0">
            无可导入信息
          </div>
        </div>
      </div>
    </b-modal>
    <!-- 导入学历弹窗结束 -->

    <!-- 检验学历弹窗 -->
    <recheck
      v-if="reEduForm_dialog"
      :show="reEduForm_dialog"
      :form="EduactionForm"
      :sfdrEdu="sfdrEdu"
      :xlDict="xlDict"
      :xlList="sjzd.xlList"
      @success="submitEdu"
      @close="
        reEduForm_dialog = false;
        showmodal = true;
      "
    ></recheck>
  </Layout>
</template>
